import { template as template_2a69a03da3f8445ba6ca2f600c119a74 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2a69a03da3f8445ba6ca2f600c119a74(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
