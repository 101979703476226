import { template as template_186f749e738a4b13989341f6d4778283 } from "@ember/template-compiler";
const FKText = template_186f749e738a4b13989341f6d4778283(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
